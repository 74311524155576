/*
 *  Builder Layout - Homepage
 */

// React
import React, { useState, useEffect } from "react";

// Imports
import { BuilderComponent, builder } from '@builder.io/react';

// Import & register custom components
import './components/SignUp';

// Initialize builder
builder.init("1a0daacbd4bd498290dc5fded81187be");

// Component
function Homepage() {
  // Builder states
  const [ builderContentJson, setBuilderContentJson ] = useState(null);

  // Load content
  useEffect(() => {
    builder.get('page', { url: '/' })
      .promise().then(setBuilderContentJson);
  }, []);

  return <BuilderComponent model="page" content={builderContentJson} />
}

export default Homepage;
