export const version = "1.0.0";

export const accountId = "";

export const env = "prod";

export const firebaseConfig = {
  apiKey: "AIzaSyDSB8BMHyRsW5r-i1jl5CGTiyMADdMT18M",
  authDomain: "sell-design.firebaseapp.com",
  projectId: "sell-design",
  storageBucket: "sell-design.appspot.com",
  messagingSenderId: "191632666945",
  appId: "1:191632666945:web:90485d61b095257332b1c0",
  measurementId: "G-NXCF7TG5BB"
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "http://localhost:5001/axon-platform/us-central1",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://localhost:9000",
};

export const configs = {
  baseUrl: "https://axon-config.ngrok.io",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
