// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - profile
export const INITIALIZE_PROFILE = '@profile/INITIALIZE_PROFILE';
export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const UPDATE_PROFILE_USER = '@profile/UPDATE_PROFILE_USER';
export const UPDATE_WELCOME_STAGE = '@profile/UPDATE_WELCOME_STAGE';

// action - portfolio
export const INITIALIZE_PORTFOLIO = '@portfolio/INITIALIZE_PORTFOLIO';
export const SET_ARTIST = '@portfolio/SET_ARTIST';
export const SET_ARTWORKS = '@portfolio/SET_ARTWORKS';
export const SET_ACTIVE_ARTWORK = '@portfolio/SET_ACTIVE_ARTWORK';
export const SET_COLLECTIONS = '@portfolio/SET_COLLECTIONS';
export const SET_ACTIVE_COLLECTION = '@portfolio/SET_ACTIVE_COLLECTION';
export const SET_EDITIONS = '@portfolio/SET_EDITIONS';
export const SET_ACTIVE_EDITION = '@portfolio/SET_ACTIVE_EDITION';

// action - gallery
export const INITIALIZE_GALLERY = '@gallery/INITIALIZE_GALLERY';
