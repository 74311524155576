/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

function Home({ profile, dispatch }) {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Link to="/admin/artworks">
              <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Manage
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        Portfolio
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-palette" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col xl="4">
            <Link to="/admin/social">
              <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Design
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        Showcase
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-album-2" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Link>
          </Col>
          <Col xl="4">
            <Link to="/admin/stories">
              <Card className="bg-gradient-primary border-0" style={{ cursor: 'pointer' }}>
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Create
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        Studio
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-scissors" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">Sales value</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <h5 className="h3 mb-0">New Patrons</h5>
              </CardHeader>

              <CardBody>
                <ListGroup className="list my--3" flush>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-1.jpg").default}
                          />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            John Michael
                          </a>
                        </h4>
                        <small>
                          <a href="#">Coming Home</a>
                        </small>
                        <br/>
                        <small>taudalpoi.art</small>
                      </div>
                      <Col className="col-auto">
                        <Button color="primary" size="sm" type="button">
                          View
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-2.jpg").default}
                          />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Alex Smith
                          </a>
                        </h4>
                        <small>
                          <a href="#">Illusionary Boat Ride</a>
                        </small>
                        <br/>
                        <small>myarthaus.com</small>
                      </div>
                      <Col className="col-auto">
                        <Button color="primary" size="sm" type="button">
                          View
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-3.jpg").default}
                          />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Samantha Ivy
                          </a>
                        </h4>
                        <small>
                          <a href="#">Coming in Hot</a>
                        </small>
                        <br/>
                        <small>taudalpoi.art</small>
                      </div>
                      <Col className="col-auto">
                        <Button color="primary" size="sm" type="button">
                          View
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="px-0">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <a
                          className="avatar rounded-circle"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("assets/img/theme/team-4.jpg").default}
                          />
                        </a>
                      </Col>
                      <div className="col ml--2">
                        <h4 className="mb-0">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            John Michael
                          </a>
                        </h4>
                        <small>
                          <a href="#">Van Life</a>
                        </small>
                        <br/>
                        <small>taudalpoi.art</small>
                      </div>
                      <Col className="col-auto">
                        <Button color="primary" size="sm" type="button">
                          View
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile
}))(Home);

export default ConnectedHome;
