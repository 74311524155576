import { combineReducers } from 'redux';

// Dynamic Loyalty reducers
import profileReducer from './profile';
import portfolioReducer from './portfolio';
import galleryReducer from './gallery';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    profile: profileReducer,
    portfolio: portfolioReducer,
    gallery: galleryReducer
});

export default reducer;
