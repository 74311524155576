/*
 *  Builder Component - Sign Up
 */

// React
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Imports
import { Builder, BuilderComponent } from '@builder.io/react'

// Config
import config from "config";

// Firebase Auth
import { auth, firestore } from "./../../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// Auth actions
import {
  INITIALIZE_PROFILE
} from 'store/actions';

// Styles
import './styles.css';

function SignUp({ display, profile, dispatch }) {
  // Eamil SignUp
  const [email, setEmail] = useState("");

  // Get history
  const history = useHistory();

  // Hangle submit
  const handleSignup = async (e) => {
    // Stop default behavior
    e.preventDefault();

    // Trigger sign-up
    console.log("Creating New Account: ", email);

    // Sign up user - default password
    try {
      // Sign up with firebase
      const userCredential = await auth.createUserWithEmailAndPassword(email, "selldesign");
      console.log(userCredential);

      // Load / configure user profile
      if(userCredential.user) {
        loadProfile(userCredential.user, "password");
      }

      // Send to shop page
      window.location.href = "https://myarthaus.com/collections/art-prints/?utm_source=design_landing_page&utm_campaign=1+-+Acquisition+%7C+Trade+%7C+Sign-Up";
    } catch (error) {
      console.log('error signing up:', error);

      // Send to shop page
      window.location.href = "https://myarthaus.com/collections/art-prints/?utm_source=design_landing_page&utm_campaign=1+-+Acquisition+%7C+Trade+%7C+Sign-Up";
    }
  }

  const loadProfile = async (user, provider) => {
    // Find profile
    console.log(user);

    const profilesRef = firestore.collection("profiles");
    const profile = await profilesRef.doc(user.uid).get();
    if(profile.exists) {
      // Parse profile
      const profileData = profile.data();
      console.log("Profile Exists: ", profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });

      // Send to home
      //history.push("/admin/profile");
    } else {
      console.log("Profile does not exist.");

      // Create initial profile data
      const profileData = {
        uid: user.uid,
        name: user.displayName,
        authProvider: provider,
        email: user.email,
        complete: false,
        details: {
          firstName: (user.displayName && user.displayName.split(" ").length > 1) ? user.displayName.split(" ")[0] : null,
          lastName: null
        },
        account: {
          role: "USER",
          clientId: "arthaus",
          imageUrl: config.account.imageUrl,
          membership: [ config.account.id ]
        }
      };

      // Create new profile with authentication
      const newProfile = await profilesRef.doc(user.uid).set(profileData);

      // Initialize profile
      dispatch({
        type: INITIALIZE_PROFILE,
        payload: profileData
      });

      // Send to home
      //history.push("/admin/home");
    }
  }

  // Return component
  return (
    <div className="portal-signup">
      <input
        className="portal-signup-email"
        type="text"
        placeholder="Designer email address..."
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <div
        className="portal-signup-button"
        onClick={handleSignup}
      >
        { display }
      </div>
    </div>
  )
}

// Connect to store
const ConnectedSignUp = connect(state => ({
  profile: state.profile
}))(SignUp);

Builder.registerComponent(ConnectedSignUp, {
  name: 'SignUp',
  inputs: [
    {
      name: "display",
      type: "text",
      defaultValue: "Join"
    }
  ]
});
