/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Imports
import Sample from "views/pages/Sample.js";

// Auth
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

// Portfolio
import Profile from "views/portfolio/profile";

const routes = [
  {
    collapse: true,
    name: "Portfolio",
    display: true,
    icon: "ni ni-palette text-default",
    state: "portfolioCollapse",
    views: [
      {
        path: "/profile",
        display: false,
        component: Profile,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Examples",
    display: false,
    icon: "ni ni-ungroup",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        display: false,
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      }
    ]
  },
];

export default routes;
